import React, { useRef, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";

export interface Message {
  sender: "user" | "bot";
  text: string;
}

interface ChatHistoryProps {
  messages: Message[];
}

export const ChatHistory: React.FC<ChatHistoryProps> = ({ messages }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Auto-scroll to the bottom when new messages arrive
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        ref={chatContainerRef}
        sx={{
          maxHeight: "300px", // Adjust this value to set the fixed height of the chat container
          overflowY: "auto",
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: message.sender === "user" ? "row-reverse" : "row",
              mb: 1,
            }}
          >
            <Box
              sx={{
                minWidth: 200,
                maxWidth: 400,
                borderRadius: 8,
                p: 1,
                //   bg: message.sender === "user" ? "#3f51b5" : "#f50057",
                color: "white",
              }}
              bgcolor={message.sender === "user" ? "#3f51b5" : "#607d8b"}
            >
              {/* <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {message.sender === "user" ? "You" : "Bot"}
            </Typography> */}
              <Typography variant="body1">{message.text}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
