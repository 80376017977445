import { Configuration, OpenAIApi } from "openai";

const configuration = new Configuration({
  organization: "org-k71NIQIHKcx9RSLM6wLZfC4T",
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);

// The constant part of the prompt
const TEACHER_BOT_PROMPT = "You are a helpful teacher bot.";

export default async function handler(
  userAnswer: string,
  correctAnswer: string
): Promise<string> {
  const completion = await openai.createChatCompletion({
    model: "gpt-3.5-turbo-0301",
    messages: [
      {
        role: "system",
        content:
          "You are responding to a student who has been shown the lights from a ship and is being asked what type of ship it is. You are to respond with a very short to try and guide the student to the right answer but don't give away the correct answer.",
      },
      {
        role: "user",
        content: `The correct answer is "${correctAnswer}". The student has said "${userAnswer}"  `,
      },
    ],
  });

  if (completion.data.choices && completion.data.choices.length > 0) {
    return completion.data.choices[0].message?.content ?? "";
  } else {
    throw new Error("No choices available in completion");
  }
}
