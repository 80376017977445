export const questions = [
  {
    questionID: "a7136404",
    imgPath: "/images/a7136404.png",
    answer: [
      " a sailing vessel port side",
      "sailing vessel  port side ",
      "a sailing vessel underway port side",
      "sailing vessel underway port side",
      "a sailing vessel port side aspect",
      "sailing vessel  port side aspect",
      "a sailing vessel underway port side aspect",
      "sailing vessel underway port side aspect",
      "port side aspect of a sailing vessel",
      "port side aspect of a sailing vessel",
      "port side aspect of a sailing vessel underway",
      "port side aspect of sailing vessel underway",
      "port side of a sailing vessel",
      "port side of a sailing vessel",
      "port side of a sailing vessel underway",
      "port side of a sailing vessel underway",
      "port side sailing vessel",
      "port side sailing vessel",
      "port side sailing vessel underway",
      "port side sailing vessel underway",
      "port side aspect sailing vessel",
      "port side aspect sailing vessel",
      "port side aspect sailing vessel underway",
      "port side aspect sailing vessel underway",
    ],
  },
  {
    questionID: "b62dcfa6",
    imgPath: "/images/b62dcfa6.png",
    answer: [
      "a power-driven vessel head-on",
      "a power driven vessel head-on",
      "power driven vessel head-on",
      "power-driven vessel head-on",
      "a power-driven vessel head on",
      "a power driven vessel head on",
      "power driven vessel head on",
      "power-driven vessel head on",
      "a power-driven vessel head-on aspect",
      "a power driven vessel head-on aspect",
      "power driven vessel head-on aspect",
      "power-driven vessel head-on aspect",
      "a power-driven vessel head on aspect",
      "a power driven vessel head on aspect",
      "power driven vessel head on aspect",
      "power-driven vessel head on aspect",
      "head-on aspect of a power driven vessel ",
      "head-on aspect of power driven vessel",
      "head-on aspect of power-driven vessel",
      "head on aspect of a power-driven vessel",
      "head on aspect of a power driven vessel",
      "head on aspect of power driven vessel",
      "head on aspect of power-driven vessel",
      "a pdv head on aspect",
      "pdv head on aspect",
      "head-on aspect of a pdv ",
      "head-on aspect of pdv",
      "head on aspect of a pdv",
      "head on aspect of pdv",
      "head on aspect of pdv",
      "head on aspect of pdv",
    ],
  },
  {
    questionID: "ce85993a",
    imgPath: "/images/ce85993a.png",
    answer: [
      "a vessel engaged in fishing other than trawling starboard side",
      "vessel engaged in fishing other than trawling starboard side",
      "a veif other than trawling starboard side",
      "veif other than trawling starboard side",
      "a vessel engaged in fishing not trawling starboard side",
      "vessel engaged in fishing not trawling starboard side",
      "a veif not trawling starboard side",
      "veif not trawling starboard side",
      "a vessel engaged in fishing other than trawling starboard side aspect",
      "vessel engaged in fishing other than trawling starboard side aspect",
      "a veif other than trawling starboard side aspect",
      "veif other than trawling starboard side aspect",
      "a vessel engaged in fishing not trawling starboard side aspect",
      "vessel engaged in fishing not trawling starboard side aspect",
      "a veif not trawling starboard side aspect",
      "veif not trawling starboard side aspect",
      "starboard side aspect of a vessel engaged in fishing other than trawling",
      "starboard side aspect vessel engaged in fishing other than trawling ",
      "starboard side aspect of a veif other than trawling",
      "starboard side aspect veif other than trawling ",
      "starboard side aspect a vessel engaged in fishing not trawling",
      "starboard side aspect vessel engaged in fishing not trawling",
      "starboard side aspect a veif not trawling",
      "starboard side veif not trawling",
      "starboard side of a vessel engaged in fishing other than trawling",
      "starboard side vessel engaged in fishing other than trawling ",
      "starboard side of a veif other than trawling",
      "starboard side veif other than trawling ",
      "starboard side a vessel engaged in fishing not trawling",
      "starboard side vessel engaged in fishing not trawling",
      "starboard side a veif not trawling",
      "starboard side veif not trawling",
    ],
  },
  {
    questionID: "db90e058",
    imgPath: "/images/db90e058.png",
    answer: [
      "restricted in ability to manoeuvre making way through the water stern",
      "restricted in ability to maneuver making way through the water stern",
      "ram making way through the water stern",
      "restricted in ability to manoeuvre making way stern",
      "restricted in ability to maneuver making way stern",
      "ram making way stern",
      "restricted in ability to manoeuvre vessel making way through the water stern",
      "restricted in ability to maneuver  vessel making way through the water stern",
      "ram vessel making way through the water stern",
      "restricted in ability to manoeuvre  vessel making way stern",
      "restricted in ability to maneuver  vessel making way stern",
      "ram vessel making way stern",
      "a vessel restricted in ability to manoeuvre making way through the water stern aspect",
      "a vessel restricted in ability to maneuver making way through the water stern aspect",
      "a vessel ram making way through the water stern aspect",
      "a vessel restricted in ability to manoeuvre making way stern aspect",
      "a vessel restricted in ability to maneuver making way stern aspect",
      "a vessel ram making way stern aspect",
      "a restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "a restricted in ability to maneuver vessel making way through the water stern aspect",
      "a ram vessel making way through the water stern aspect",
      "a restricted in ability to manoeuvre vessel making way stern aspect",
      "a restricted in ability to maneuver vessel making way stern aspect",
      "a ram vessel making way stern aspect",
      "restricted in ability to manoeuvre making way through the water stern aspect",
      "restricted in ability to maneuver making way through the water stern aspect",
      "ram making way through the water stern aspect",
      "restricted in ability to manoeuvre making way stern aspect",
      "restricted in ability to maneuver making way stern aspect",
      "ram making way stern aspect",
      "restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "restricted in ability to maneuver  vessel making way through the water stern aspect",
      "ram vessel making way through the water stern aspect",
      "restricted in ability to manoeuvre  vessel making way stern aspect",
      "restricted in ability to maneuver  vessel making way stern aspect",
      "ram vessel making way stern aspect",
      "a vessel restricted in ability to manoeuvre making way through the water stern aspect",
      "a vessel restricted in ability to maneuver making way through the water stern aspect",
      "a vessel ram making way through the water stern aspect",
      "a vessel restricted in ability to manoeuvre making way stern aspect",
      "a vessel restricted in ability to maneuver making way stern aspect",
      "a vessel ram making way stern aspect",
      "a restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "a restricted in ability to maneuver vessel making way through the water stern aspect",
      "a ram vessel making way through the water stern aspect",
      "a restricted in ability to manoeuvre vessel making way stern aspect",
      "a restricted in ability to maneuver vessel making way stern aspect",
      "a ram vessel making way stern aspect",
      "a vessel restricted in ability to manoeuvre making way through the water stern aspect",
      "a vessel restricted in ability to maneuver making way through the water stern aspect",
      "a vessel ram making way through the water stern aspect",
      "a vessel restricted in ability to manoeuvre making way stern aspect",
      "a vessel restricted in ability to maneuver making way stern aspect",
      "a vessel ram making way stern aspect",
      "a restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "a restricted in ability to maneuver vessel making way through the water stern aspect",
      "a ram vessel making way through the water stern aspect",
      "a restricted in ability to manoeuvre vessel making way stern aspect",
      "a restricted in ability to maneuver vessel making way stern aspect",
      "a ram vessel making way stern aspect",
      "restricted in ability to manoeuvre making way through the water stern aspect",
      "restricted in ability to maneuver making way through the water stern aspect",
      "ram making way through the water stern aspect",
      "restricted in ability to manoeuvre making way stern aspect",
      "restricted in ability to maneuver making way stern aspect",
      "ram making way stern aspect",
      "restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "restricted in ability to maneuver  vessel making way through the water stern aspect",
      "ram vessel making way through the water stern aspect",
      "restricted in ability to manoeuvre  vessel making way stern aspect",
      "restricted in ability to maneuver  vessel making way stern aspect",
      "ram vessel making way stern aspect",
      "a vessel restricted in ability to manoeuvre making way through the water stern aspect",
      "a vessel restricted in ability to maneuver making way through the water stern aspect",
      "a vessel ram making way through the water stern aspect",
      "a vessel restricted in ability to manoeuvre making way stern aspect",
      "a vessel restricted in ability to maneuver making way stern aspect",
      "a vessel ram making way stern aspect",
      "a restricted in ability to manoeuvre vessel making way through the water stern aspect",
      "a restricted in ability to maneuver vessel making way through the water stern aspect",
      "a ram vessel making way through the water stern aspect",
      "a restricted in ability to manoeuvre vessel making way stern aspect",
      "a restricted in ability to maneuver vessel making way stern aspect",
      "a ram vessel making way stern aspect",
    ],
  },
  {
    questionID: "e50c354c",
    imgPath: "/images/e50c354c.png",
    answer: [
      "vessel not under command making way stern",
      "vessel not under command making way stern aspect",
      "vessel not under command making way a stern aspect",
      "vessel not under command making way through the water stern",
      "vessel not under command making way through the water stern aspect",
      "vessel not under command making way through the water a stern aspect",
      "a vessel not under command making way stern",
      "a vessel not under command making way stern aspect",
      "a vessel not under command making way a stern aspect",
      "a vessel not under command making way through the water stern",
      "a vessel not under command making way through the water stern aspect",
      "a vessel not under command making way through the water a stern aspect",
      "stern of a vessel not under command making way ",
      "stern aspect of a vessel not under command making way ",
      "a stern aspect of a vessel not under command making way ",
      "stern of a vessel not under command making way through the water ",
      "stern aspect of a vessel not under command making way through the water ",
      "a stern aspect of a vessel not under command making way through the water ",
      "not under command making way stern",
      "not under command making way stern aspect",
      "not under command making way a stern aspect",
      "not under command making way through the water stern",
      "not under command making way through the water stern aspect",
      "not under command making way through the water a stern aspect",
      "nuc making way stern",
      "nuc making way stern aspect",
      "nuc making way a stern aspect",
      "nuc making way through the water stern",
      "nuc making way through the water stern aspect",
      "nuc making way through the water a stern aspect",
      "a nuc making way stern",
      "a nuc making way stern aspect",
      "a nuc making way a stern aspect",
      "a nuc making way through the water stern",
      "a nuc making way through the water stern aspect",
      "a nuc making way through the water a stern aspect",
      "stern of a nuc making way ",
      "stern aspect of a nuc making way ",
      "a stern aspect of a nuc making way ",
      " stern of a nuc making way through the water",
      "stern aspect of a nuc making way through the water",
      "a stern aspect of a nuc making way through the water",
      "undefined making way stern",
      "undefined making way stern aspect",
      "undefined making way a stern aspect",
      "undefined making way through the water stern",
      "undefined making way through the water stern aspect",
      "undefined making way through the water a stern aspect",
    ],
  },
];
